import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Interweave } from "interweave";
import {
  Button,
  Modal,
  Card,
  Input,
  Flex,
  Badge,
  Switch,
  Typography,
  Tooltip,
  Radio,
  Space,
  InputNumber,
  Checkbox,
  Alert,
  Select,
  message,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

//icons
import {
  CloseOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  EllipsisOutlined,
  CloseSquareOutlined,
  CheckSquareOutlined,
  QuestionCircleOutlined,
  StepBackwardOutlined,
  StepForwardOutlined,
  FormOutlined,
  DeleteOutlined,
  SnippetsOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { LuFlag, LuFlagOff } from "react-icons/lu";
import { PiQuestionBold } from "react-icons/pi";

//components app
import Wysiwyg from "../../../components/app/Wysiwyg";

//styles
import "../../../assets/styles/builder/build.css";

//utils
import { getStorage, setStorage } from "../../../utils/Storage";
import { inputProps } from "../../../utils/String";
import i18n from "../../../utils/i18n";

//redux
import { updateX, setUpdateX } from "../../../store/builder/builder.slice";

const AbaQuestion = () => {
  const { surveyid } = useParams();
  const dispatch = useDispatch();
  const { TextArea } = Input;
  const { Text } = Typography;

  //states
  const [messageApi, contextHolder] = message.useMessage();
  const [openModalQuestionTypes, setOpenModalQuestionTypes] = useState(false);
  const [openModalImportOptions, setOpenModalImportOptions] = useState(false);
  const [openImportAutoComplete, setOpenImportAutoComplete] = useState(false);
  const [openImportRadiobox, setOpenImportRadiobox] = useState(false);
  const [openImportCheckbox, setOpenImportCheckbox] = useState(false);
  const [openPastInRadiobox, setOpenPastInRadiobox] = useState(false);
  const [openPastInCheckbox, setOpenPastInCheckbox] = useState(false);
  const [settings, setSettings] = useState(null); //dado completo da questão
  const [require, setRequire] = useState(false); //se é obrigatório
  const [textTitle, setTextTitle] = useState(""); //texto da questão ou componente
  const [questionType, setQuestionType] = useState(""); //tipo de questão
  const [questionOptions, setQuestionOptions] = useState(null); //opções da questão
  const [naOptionLeft, setNaOptionLeft] = useState(false); //na option
  const [naOptionRight, setNaOptionRight] = useState(false); //na option
  const [maxLength, setMaxLength] = useState(250); //máximo de respostas
  const [persistAnswer, setPersistAnswer] = useState(false); //optin
  const [properties, setProperties] = useState({}); //optin
  const [lang, setLang] = useState(""); //linguagem
  const [maxIdOption, setMaxIdOption] = useState(0); //id máximo de options
  const [questionActive, setQuestionActive] = useState(true); //se a uqestão está ativa

  //states redux
  const update = useSelector(updateX);

  const setLocalData = () => {
    let qData = getStorage("EDITION_" + surveyid, true);
    const surveyData = getStorage("SURVEY_" + surveyid, true);
    let lastOptionId = 0;

    setLang(surveyData.language);
    setRequire(qData.require);
    setQuestionActive(qData.active);
    setTextTitle(qData.title[surveyData.language]);
    setQuestionType(qData.type);
    setNaOptionRight(qData?.properties?.naOptionRight);
    setNaOptionLeft(qData?.properties?.naOptionLeft);
    setPersistAnswer(qData?.properties?.persistAnswer || "false");
    setProperties(qData?.properties || {});
    setMaxLength(
      qData?.properties?.maxLength || qData.type === "input" ? 250 : 1000
    );

    if (["radiobox", "checkbox"].includes(qData.type)) {
      qData.options.forEach((o) => {
        const splitedOptId = o.id.split("-")[1];
        if (splitedOptId > lastOptionId) {
          lastOptionId = parseInt(splitedOptId);
        }
      });
    }

    setMaxIdOption(lastOptionId);
    setQuestionOptions(qData.options);
    setSettings(qData);
    setStorage("EDITION_" + surveyid, qData, true);
  };

  useEffect(() => {
    setLocalData();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setLocalData();
    // eslint-disable-next-line
  }, [update]);

  const selectQuestionType = (type) => {
    let settingsCopy = getStorage("EDITION_" + surveyid, true);
    const dataSurvey = getStorage("SURVEY_" + surveyid, true);

    settingsCopy.type = type;
    settingsCopy.title[lang] = textTitle;
    settingsCopy.properties = {};
    let options = [];

    if (
      ["radiobox", "checkbox", "dropdown"].includes(type) &&
      ["radiobox", "checkbox", "dropdown"].includes(settingsCopy.type)
    ) {
      options = settingsCopy.options;
    }

    settingsCopy.options = options;

    if (["text", "optin"].includes(type)) {
      settingsCopy.require = false;
    } else if (type === "nps") {
      settingsCopy.options = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
      ];
    } else if (type === "csat") {
      settingsCopy.options = ["1", "2", "3", "4", "5"];
    } else if (["radiobox", "checkbox", "dropdown"].includes(type)) {
      if (settingsCopy.options.length <= 0) {
        let optMaxId = maxIdOption;

        for (let index = 1; index <= 2; index++) {
          let labelTexts = {};
          optMaxId += 1;

          dataSurvey.languageOptions.forEach((l) => {
            labelTexts[l.value] = "Item " + index;
          });

          settingsCopy.options.push({
            label: labelTexts,
            value: "Item " + index,
            id: settingsCopy.id + "-" + optMaxId,
          });
        }

        setMaxIdOption(optMaxId);
      }
    } else if (type === "auto-complete") {
      settingsCopy.options = ["Item 1", "Item 2", "Item 3"];
      settingsCopy.properties.onlyOptions = true;
    } else if (type === "language-dropdown") {
      if (dataSurvey.languageOptions.length <= 1) {
        toast.warning(
          "Necessário ter mais de um idioma na pesquisa para usar essa questão!",
          { autoClose: 3000 }
        );
        return;
      }
    } else if (type === "textarea") {
      settingsCopy.properties.maxLength = 1000;
    } else if (type === "input") {
      settingsCopy.properties.maxLength = 250;
    } else if (type === "image-upload") {
      settingsCopy.properties = {
        acceptTypes: ["image/png", "image/jpg", "image/jpeg"],
        limit: 4,
        maxSize: 1024,
      };
    }

    setSettings(settingsCopy);
    setStorage("EDITION_" + surveyid, settingsCopy, true);
    setQuestionOptions(settingsCopy.options);
    setQuestionType(type);
    setNaOptionRight(
      settingsCopy?.properties?.naOption ||
        settingsCopy?.properties?.naOptionRight
    );
    setNaOptionRight(settingsCopy?.properties?.naOptionLeft);
    dispatch(setUpdateX());
  };

  const customInput = () => {
    /* Informa se um input teve layout personalizado e
     * retorna o valor padrão do maxLength do campo
     * para cada tipo de tipo de input */
    if (
      properties?.tel ||
      properties?.email ||
      properties?.cpf ||
      properties?.date ||
      properties?.time ||
      properties?.datetime ||
      properties?.cnpj
    ) {
      return true;
    }

    return false;
  };

  const addMoreOptions = () => {
    const surveyData = getStorage("SURVEY_" + surveyid, true);
    let questionData = getStorage("EDITION_" + surveyid, true);

    let labelTexts = {};
    surveyData.languageOptions.forEach((l) => {
      labelTexts[l.value] = "Item " + (questionOptions.length + 1);
    });

    questionData.options.push({
      label: labelTexts,
      value: "Item " + (questionOptions.length + 1),
      id: `${questionData.id}-${maxIdOption + 1}`,
    });

    setStorage("EDITION_" + surveyid, questionData, true);
    setQuestionOptions(questionData.options);
    setMaxIdOption(maxIdOption + 1);
    dispatch(setUpdateX());
  };

  const clearOptions = () => {
    let questionData = getStorage("EDITION_" + surveyid, true);
    questionData.options = [];
    setStorage("EDITION_" + surveyid, questionData, true);
    setQuestionOptions(questionData.options);
    setMaxIdOption(0);
    dispatch(setUpdateX());
  };

  const InputCheckbox = (props) => {
    const { data, position } = props;

    const [localValue, setLocalValue] = useState(data.value);
    const [localHide, setLocalHide] = useState(data.hide);
    const [enableOthers, setEnableOthers] = useState(
      (data?.properties &&
        data.properties?.type &&
        data.properties.type === "other") ||
        ""
    );
    const [enableToFix, setEnableToFix] = useState(data.fixed);
    const [placeholder, setPlaceholder] = useState(
      data?.properties && data.properties?.placeholder
        ? data.properties.placeholder
        : ""
    );
    const [require, setRequire] = useState(
      (data?.properties && data.properties?.require) || false
    );
    const [openModalMoreOptions, setOpenModalMoreOptions] = useState(false);
    const [openModalEditOther, setOpenModalEditOther] = useState(false);

    const changeValue = (v) => {
      let questionData = getStorage("EDITION_" + surveyid, true);
      questionData.options[position].label[lang] = v;
      questionData.options[position].value = v;
      setStorage("EDITION_" + surveyid, questionData, true);
      setLocalValue(v);
    };

    const changeHidden = (v) => {
      let questionData = getStorage("EDITION_" + surveyid, true);

      if (v) {
        questionData.options[position].hidden = true;
      } else {
        delete questionData.options[position].hidden;
      }

      setStorage("EDITION_" + surveyid, questionData, true);
      setLocalHide(v);
    };

    const changeEnableOthers = (v) => {
      let questionData = getStorage("EDITION_" + surveyid, true);

      if (v) {
        if (questionData.options[position].properties) {
          questionData.options[position].properties.type = "other";
        } else {
          questionData.options[position].properties = { type: "other" };
        }
      } else {
        delete questionData.options[position].properties.type;

        //se a propriedade estiver vazia, ela será eliminada
        if (Object.keys(questionData.options[position].properties) <= 0) {
          delete questionData.options[position].properties;
        }
      }

      setStorage("EDITION_" + surveyid, questionData, true);
      setEnableOthers(v);
    };

    const changeEnableToFix = (v) => {
      let questionData = getStorage("EDITION_" + surveyid, true);

      if (v) {
        questionData.options[position].fixed = true;
      } else {
        delete questionData.options[position].fixed;
      }

      setStorage("EDITION_" + surveyid, questionData, true);
      setEnableToFix(v);
    };

    const deleteOption = () => {
      let questionData = getStorage("EDITION_" + surveyid, true);
      let newOptions = [];
      questionData.options.forEach((opt, i) => {
        if (position !== i) {
          newOptions.push(opt);
        }
      });
      questionData.options = newOptions;
      setStorage("EDITION_" + surveyid, JSON.stringify(questionData));
      setQuestionOptions(newOptions);
    };

    const saveOtherSettings = () => {
      let questionData = getStorage("EDITION_" + surveyid, true);
      let opt = questionData.options[position].properties;
      opt = { ...opt, require, placeholder };
      questionData.options[position].properties = opt;
      setStorage("EDITION_" + surveyid, questionData, true);
      setOpenModalEditOther(!openModalEditOther);
    };

    return (
      <>
        <Input
          onChange={({ target: { value } }) => changeValue(value)}
          value={localValue}
          style={{ marginBottom: 5, width: "calc(100% - 70px)" }}
          disabled={localHide}
          addonAfter={`ID ${data.id}`}
        />
        <Button
          style={{ marginBottom: 5 }}
          onClick={() => deleteOption()}
          icon={<CloseOutlined />}
        ></Button>
        <Button
          style={{ marginBottom: 5 }}
          onClick={() => setOpenModalMoreOptions(!openModalMoreOptions)}
          icon={<EllipsisOutlined />}
        ></Button>

        <p>
          {enableToFix && <Badge count="Fixo" color="#aaa" />}
          {enableOthers && (
            <>
              <Badge count="Outros" color="#aaa" />
              <FormOutlined
                className="edit-other"
                title="Editar Outros"
                onClick={() => setOpenModalEditOther(!openModalEditOther)}
              />
            </>
          )}
        </p>

        <Modal
          open={openModalMoreOptions}
          onCancel={() => setOpenModalMoreOptions(!openModalMoreOptions)}
          footer={null}
          styles={{ body: { padding: "25px 0 0 0" } }}
          style={{ padding: "0px" }}
          width={300}
        >
          <Button
            style={{ marginBottom: 5 }}
            onClick={() => changeHidden(!localHide)}
            icon={localHide ? <EyeInvisibleOutlined /> : <EyeOutlined />}
            type={localHide ? "primary" : "dashed"}
            block
          >
            {localHide ? "Oculto" : "Ocultar"}
          </Button>
          <Button
            style={{ marginBottom: 5 }}
            onClick={() => changeEnableOthers(!enableOthers)}
            icon={
              enableOthers ? <CheckSquareOutlined /> : <CloseSquareOutlined />
            }
            type={enableOthers ? "primary" : "dashed"}
            block
          >
            {enableOthers ? "Outros Habilitado" : "Habilitar Outros"}
          </Button>
          <Button
            style={{ marginBottom: 5 }}
            onClick={() => changeEnableToFix(!enableToFix)}
            icon={enableToFix ? <LuFlag /> : <LuFlagOff />}
            type={enableToFix ? "primary" : "dashed"}
            block
          >
            {enableToFix ? "Opção Fixada" : "Fixar Opção"}
          </Button>
        </Modal>
        <Modal
          title="Outros"
          open={openModalEditOther}
          onCancel={() => setOpenModalEditOther(!openModalEditOther)}
          onOk={() => saveOtherSettings()}
          cancelText={i18n.CANCEL}
        >
          <label htmlFor={`input-pr-${position}`} style={{ display: "block" }}>
            Placeholder
          </label>
          <Input
            id={`input-pr-${position}`}
            name={`input-pr-${position}`}
            onChange={({ target: { value } }) => setPlaceholder(value)}
            value={placeholder}
            style={{ margin: "5px 0 10px 0" }}
          />
          <Switch
            id={`input-r-${position}`}
            name={`input-r-${position}`}
            value={require}
            onChange={() => setRequire(!require)}
          />{" "}
          Obrigatório
        </Modal>
      </>
    );
  };

  const SelectorOptions = (props) => {
    const { val, hide, position } = props.data;
    const [localValue, setLocalValue] = useState(val); //opções da questão
    const [localHide, setLocalHide] = useState(hide); //opções da questão
    const [openModalMoreOptions, setOpenModalMoreOptions] = useState(false); //opções da questão

    const changeValue = (v) => {
      let questionData = getStorage("EDITION_" + surveyid, true);
      questionData.options[position].label[lang] = v;
      questionData.options[position].value = v;
      setStorage("EDITION_" + surveyid, questionData, true);
      setLocalValue(v);
    };

    const changeHidden = (v) => {
      let questionData = getStorage("EDITION_" + surveyid, true);

      if (v) {
        questionData.options[position].hidden = true;
      } else {
        delete questionData.options[position].hidden;
      }

      setStorage("EDITION_" + surveyid, questionData, true);
      setLocalHide(v);
    };

    const deleteOption = () => {
      let questionData = getStorage("EDITION_" + surveyid, true);
      let newOptions = [];
      questionData.options.forEach((opt, i) => {
        if (position !== i) {
          newOptions.push(opt);
        }
      });
      questionData.options = newOptions;
      setStorage("EDITION_" + surveyid, JSON.stringify(questionData));
      setQuestionOptions(newOptions);
    };

    return (
      <>
        <Input
          defaultValue={val}
          onChange={({ target: { value } }) => changeValue(value)}
          value={localValue}
          style={{ marginBottom: 5, width: "calc(100% - 70px)" }}
          disabled={localHide}
        />
        <Button
          style={{ marginBottom: 5 }}
          onClick={() => deleteOption()}
          icon={<CloseOutlined />}
        ></Button>
        <Button
          style={{ marginBottom: 5 }}
          onClick={() => setOpenModalMoreOptions(!openModalMoreOptions)}
          icon={<EllipsisOutlined />}
        ></Button>

        <Modal
          open={openModalMoreOptions}
          onCancel={() => setOpenModalMoreOptions(!openModalMoreOptions)}
          footer={null}
          styles={{ body: { padding: "25px 0 0 0" } }}
          style={{ padding: "0px" }}
          width={300}
        >
          <Button
            style={{ marginBottom: 5 }}
            onClick={() => changeHidden(!localHide)}
            icon={localHide ? <EyeInvisibleOutlined /> : <EyeOutlined />}
            type={localHide ? "primary" : "dashed"}
            block
          >
            {localHide ? "Oculto" : "Ocultar"}
          </Button>
        </Modal>
      </>
    );
  };

  const TextAreaAutoComplete = () => {
    let questionData = getStorage("EDITION_" + surveyid, true);

    const [autoComplete, setAutoComplete] = useState(
      questionData.options.join(";")
    );
    const [only, setOnly] = useState(
      questionData.properties.onlyOptions || false
    );
    const [typing, setTyping] = useState(
      questionData.properties.notType || false
    );

    const changeOnlyOptions = (value) => {
      if (value) {
        questionData.properties.onlyOptions = true;
      } else {
        delete questionData.properties.onlyOptions;
      }

      setOnly(value);
      setStorage("EDITION_" + surveyid, questionData, true);
    };

    const changeTyping = (value) => {
      if (value) {
        questionData.properties.notType = true;
      } else {
        delete questionData.properties.notType;
      }

      setTyping(value);
      setStorage("EDITION_" + surveyid, questionData, true);
    };

    return (
      <>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Text type="secondary">
            <QuestionCircleOutlined /> Coloque os dados divididos por
            ponto-vírgula (;). Ex.: Carro;Moto;Bicicleta
          </Text>
          <TextArea
            rows={4}
            value={autoComplete}
            onChange={({ target: { value } }) => {
              const regex = /(?:\r\n|\r|\n)/g;
              value = value.replace(regex, "");
              setAutoComplete(value);
              let questionData = getStorage("EDITION_" + surveyid, true);
              questionData.options = value !== "" ? value.split(";") : [];
              setStorage("EDITION_" + surveyid, questionData, true);
            }}
          />
          {autoComplete === "" && (
            <Alert type="error" message="Campo vazio!" showIcon />
          )}
          <Space direction="horizontal" style={{ width: "100%" }}>
            <Button
              onClick={() => setOpenImportAutoComplete(!openImportAutoComplete)}
            >
              Importar
            </Button>
            {autoComplete !== "" && (
              <Button type="text" onClick={() => clearOptions()}>
                Limpar
              </Button>
            )}
          </Space>
          <div>
            <Switch value={only} onChange={() => changeOnlyOptions(!only)} />{" "}
            Restringir o usuário a escolher apenas opções da lista.
          </div>
          <div>
            <Switch value={typing} onChange={() => changeTyping(!typing)} /> Não
            permitir que digite na caixa de texto.
          </div>
        </Space>
      </>
    );
  };

  const TextAreaRadioboxCheckbox = () => {
    const [values, setValues] = useState("");
    const [clear, setClear] = useState(false);

    const importDates = () => {
      toast.info("Aguarde.. esse processo pode demorar..");
      setTimeout(() => {
        let questionData = getStorage("EDITION_" + surveyid, true);
        const sanitizeValues =
          values.indexOf(";") >= 0
            ? values.replace(/(?:\r\n|\r|\n)/g, "")
            : values.replace(/(?:\r\n|\r|\n)/g, ";");
        let contentCopy = sanitizeValues.split(";");
        let optMaxId = maxIdOption;
        let finalValues = [];

        contentCopy.forEach((item) => {
          if (item !== "") {
            optMaxId += 1;

            finalValues.push({
              label: item,
              value: item,
              id: `${questionData.id}-${optMaxId}`,
            });
          }
        });

        setMaxIdOption(optMaxId);

        questionData.options = clear
          ? finalValues
          : questionData.options.concat(finalValues);

        setStorage("EDITION_" + surveyid, questionData, true);
        setQuestionOptions(questionData.options);

        switch (questionType) {
          case "radiobox":
            setOpenPastInRadiobox(!openPastInRadiobox);
            break;
          case "checkbox":
            setOpenPastInCheckbox(!openPastInCheckbox);
            break;
          default:
            break;
        }

        dispatch(setUpdateX());
      }, 1000);
    };

    return (
      <>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Text type="secondary">
            <QuestionCircleOutlined /> Cole os valores divididos por
            ponto-vírgula (;) ou pulando linhas (ENTER)
          </Text>
          <TextArea
            rows={4}
            value={values}
            maxLength={10000}
            onChange={({ target: { value } }) => setValues(value)}
          />
          <Checkbox checked={clear} onChange={() => setClear(!clear)}>
            Remover opções existentes
          </Checkbox>
          <Space direction="horizontal" style={{ width: "100%" }}>
            <Button type="primary" onClick={() => importDates()}>
              Importar
            </Button>
            {values !== "" && (
              <Button type="text" onClick={() => setValues("")}>
                Limpar
              </Button>
            )}
            <Button
              onClick={() => {
                switch (questionType) {
                  case "radiobox":
                    setOpenPastInRadiobox(!openPastInRadiobox);
                    break;
                  case "checkbox":
                    setOpenPastInCheckbox(!openPastInCheckbox);
                    break;
                  default:
                    break;
                }
              }}
            >
              Cancelar
            </Button>
          </Space>
        </Space>
      </>
    );
  };

  const ImportContent = () => {
    let fileReader;

    const [content, setContent] = useState(null);
    const [separator, setSeparator] = useState("enter");
    const [clear, setClear] = useState(false);

    const setTextBySeparator = (val) => {
      let valSplited = content;

      if (val === "enter") {
        valSplited = content.replaceAll(/\r?\n?<\/br>/gm, "</br>");
      } else if (val === "virgula") {
        valSplited = content.replaceAll(",", "</br>");
      } else if (val === "ponto-virgula") {
        valSplited = content.replaceAll(";", "</br>");
      }

      setContent(valSplited);
      setSeparator(val);
    };

    const handleFileRead = () => {
      const cont = fileReader.result;

      if (questionType === "auto-complete") {
        if (cont.indexOf(";") <= -1) {
          toast.error("Nenhuma divisão por (;) encontrada.");
          return;
        }

        setContent(cont.replaceAll(";", "</br>"));
      } else {
        setContent(cont.replaceAll(/\r?\n?<\/br>/gm, "</br>"));
      }
    };

    const handleFileChosen = (file) => {
      if (!["text/plain", "text/csv"].includes(file.type)) {
        toast.error(
          "Extensão de arquivo não aceita. Importar apenas .CSV ou .TXT"
        );
        return;
      }

      //100kb
      if (file.size > 102400) {
        toast.error("Arquivo maior do que 100kb");
        return;
      }

      fileReader = new FileReader();
      fileReader.onloadend = handleFileRead;
      fileReader.readAsText(file);
    };

    const importDates = () => {
      toast.info(
        "Aguarde.. esse processo pode demorar a depender da quantidade.",
        { autoClose: 2000 }
      );

      setTimeout(() => {
        let questionData = getStorage("EDITION_" + surveyid, true);
        let contentCopy = content.split("</br>");
        let maxIdOptionLocal = clear ? 0 : maxIdOption;

        if (questionType !== "auto-complete") {
          contentCopy = contentCopy.map((item) => {
            maxIdOptionLocal += 1;

            return {
              label: item,
              value: item,
              id: `${questionData.id}-${maxIdOptionLocal}`,
            };
          });
        }

        if (clear) {
          questionData.options = contentCopy;
        } else {
          questionData.options = questionData.options.concat(contentCopy);
        }

        setStorage("EDITION_" + surveyid, questionData, true);
        setQuestionOptions(questionData.options);
        setContent(null);
        setMaxIdOption(maxIdOptionLocal);

        switch (questionType) {
          case "dropdown":
            setOpenModalImportOptions(false);
            break;
          case "auto-complete":
            setOpenImportAutoComplete(false);
            break;
          case "radiobox":
            setOpenImportRadiobox(false);
            break;
          case "checkbox":
            setOpenImportCheckbox(false);
            break;
          default:
            break;
        }

        dispatch(setUpdateX());
      }, 1000);
    };

    let radioOptions = () => {
      let opts = [
        {
          label: "Enter [↵]",
          value: "enter",
        },
        {
          label: "Ponto-Vírgula [;]",
          value: "ponto-virgula",
        },
      ];

      if (questionType === "dropdown") {
        opts.push({
          label: "Vírgula [,]",
          value: "virgula",
        });
      }

      return opts;
    };

    const orientationText =
      questionType === "dropdown" ? (
        <li>
          &bull; Divida os valores pulando linhas, Vírgula (,) ou Ponto-vírgula
          (;).
        </li>
      ) : questionType === "auto-complete" ? (
        <>
          <li>&bull; Divida as opções por Ponto-vírgula (;).</li>
          <li>&bull; Necessário ter pelo menos duas opções na lista.</li>
        </>
      ) : (
        <li>&bull; Divida os valores pulando linhas ou Ponto-vírgula(;).</li>
      );

    return (
      <div id="upload-file-options">
        {content !== null ? (
          <Space direction="vertical" style={{ width: "100%" }}>
            <div className="preview">{<Interweave content={content} />}</div>
            Separar por:
            <Radio.Group
              options={radioOptions()}
              onChange={({ target: { value } }) => setTextBySeparator(value)}
              value={separator}
              optionType="button"
            />
            <Checkbox checked={clear} onChange={() => setClear(!clear)}>
              Remover opções existentes
            </Checkbox>
            <Space direction="horizontal" align="end">
              <Button onClick={() => setContent(null)}>REFAZER</Button>
              <Button onClick={() => importDates()} type="primary">
                IMPORTAR
              </Button>
            </Space>
          </Space>
        ) : (
          <Space direction="vertical" style={{ width: "100%" }}>
            <label htmlFor="input-file" className="btn-upload-file">
              IMPORTAR ARQUIVO
            </label>
            <input
              type="file"
              className="input-file"
              name="input-file"
              id="input-file"
              accept="text/csv,text/plain"
              onChange={({ target: { files } }) => handleFileChosen(files[0])}
            />
            <Alert
              message="ATENÇÃO!"
              description={
                <ul>
                  <li>
                    &bull; Aceito apenas arquivos com extensão .csv ou .txt.
                  </li>
                  <li>&bull; Tamanho máximo de 100kb.</li>
                  {orientationText}
                </ul>
              }
              type="warning"
              showIcon
            />
          </Space>
        )}
      </div>
    );
  };

  const BuildOptions = () => {
    if (
      ["nps", "csat", "radiobox", "checkbox", "dropdown"].includes(questionType)
    ) {
      return (
        <div className="item">
          <strong>{i18n.OPTIONS}</strong>
          <div className="content">
            {["nps", "csat"].includes(questionType) && (
              <div className={`${questionType}`}>
                {questionOptions.map((o, i) => {
                  return (
                    <React.Fragment key={`item-o-${i}`}>
                      {naOptionLeft && i === 0 && (
                        <div className="input-item">N/A</div>
                      )}
                      <div className="input-item">{o}</div>
                      {naOptionRight && i === 4 && (
                        <div className="input-item">N/A</div>
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
            )}

            {["radiobox", "checkbox"].includes(questionType) && (
              <>
                {questionOptions.map((o, i) => {
                  return (
                    <React.Fragment key={`item-rbox-${i}`}>
                      <div className={`${questionType}`}>
                        <div className="input-item">
                          <InputCheckbox data={o} position={i} />
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
                <Flex gap="small" wrap="wrap">
                  <Button
                    type="primary"
                    onClick={() => addMoreOptions()}
                    icon={<PlusOutlined />}
                    title={i18n.ADD}
                  />
                  <Button
                    onClick={() =>
                      questionType === "radiobox"
                        ? setOpenPastInRadiobox(!openPastInRadiobox)
                        : setOpenPastInCheckbox(!openPastInCheckbox)
                    }
                    icon={<SnippetsOutlined />}
                    title={i18n.PASTE}
                  />
                  <Button
                    onClick={() =>
                      questionType === "radiobox"
                        ? setOpenImportRadiobox(!openImportRadiobox)
                        : setOpenImportCheckbox(!openImportCheckbox)
                    }
                    icon={<UploadOutlined />}
                    title={i18n.IMPORT}
                  />
                  {questionOptions.length > 0 && (
                    <Button
                      type="dashed"
                      onClick={() => clearOptions()}
                      icon={<DeleteOutlined />}
                      title={i18n.CLEAR}
                      danger
                    />
                  )}
                </Flex>
              </>
            )}
            {questionType === "dropdown" && (
              <>
                {questionOptions.map((o, i) => {
                  return (
                    <React.Fragment key={`item-dd-${i}`}>
                      <div className={`${questionType}`}>
                        <div className="input-item">
                          <SelectorOptions
                            data={{
                              val: o.value,
                              hide: o.hidden,
                              position: i,
                            }}
                          />
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
                <Flex gap="small" wrap="wrap">
                  <Button
                    type="primary"
                    onClick={() => addMoreOptions()}
                    icon={<PlusOutlined />}
                  />
                  <Button
                    onClick={() =>
                      setOpenModalImportOptions(!openModalImportOptions)
                    }
                    icon={<UploadOutlined />}
                    title={i18n.IMPORT}
                  />
                  {questionOptions.length > 0 && (
                    <Button
                      type="dashed"
                      onClick={() => clearOptions()}
                      icon={<DeleteOutlined />}
                      title={i18n.CLEAR}
                      danger
                    />
                  )}
                </Flex>
              </>
            )}
          </div>
        </div>
      );
    }
  };

  const BuildParams = () => {
    /* Trás um modelo de máscara de acordo com o tipo do input */
    const renderInputMasks = () => {
      if (properties?.tel) {
        return {
          label: "Telefone",
          mask: "(00) 00000-0000 | (000) 000-0000",
        };
      } else if (properties?.email) {
        return {
          label: "Email",
          mask: "nickname@email.com",
        };
      } else if (properties?.cpf) {
        return {
          label: "CPF",
          mask: "000.000.000-00",
        };
      } else if (properties?.date) {
        return {
          label: "Data",
          mask: "00/00/0000",
        };
      } else if (properties?.time) {
        return {
          label: "Horário",
          mask: "00:00",
        };
      } else if (properties?.datetime) {
        return {
          label: "Data e Horário",
          mask: "00/00/0000 00:00",
        };
      } else if (properties?.cnpj) {
        return {
          label: "CNPJ",
          mask: "00.000.000/0000-00",
        };
      } else {
        return {
          label: "Texto",
          mask: "Lorem Ipsum is simply dummy text...",
        };
      }
    };

    const RenderTextarea = () => {
      const [maxLength, setMaxLength] = useState(properties?.maxLength || "");

      return (
        <>
          {questionType === "textarea" && (
            <div className="item">
              <strong>
                Máximos de caracteres permitidos
                <Tooltip title={i18n.BUILD_MSG_9} style={{ display: "inline" }}>
                  <span>
                    {" "}
                    <QuestionCircleOutlined />
                  </span>
                </Tooltip>
              </strong>
              <div className="content">
                <Input
                  defaultValue={maxLength}
                  maxLength={5}
                  value={maxLength}
                  placeholder={i18n.BUILD_MSG_8}
                  onChange={({ target: { value } }) => {
                    let questionData = getStorage("EDITION_" + surveyid, true);
                    let rx = /^([\d]{1,4})$|^10000$/g;
                    const resultTest = rx.test(value);

                    if (resultTest) {
                      value = parseInt(value);

                      if (value <= 10000) {
                        questionData.properties.maxLength = value;
                      }

                      if (value > 10000) {
                        questionData.properties.maxLength = 10000;
                        value = 10000;
                      }

                      if (value === 0) {
                        delete questionData.properties.maxLength;
                        toast.error("Insira valores entre 1 e 10000");
                        value = "";
                      }

                      setMaxLength(value + "");
                    }

                    if (value === "") {
                      delete questionData.properties.maxLength;
                      setMaxLength("");
                    }

                    setStorage("EDITION_" + surveyid, questionData, true);
                  }}
                />
              </div>
            </div>
          )}
        </>
      );
    };

    return (
      <>
        <RenderTextarea />
        {questionType === "csat" &&
          !properties?.likeDeslike &&
          !properties?.faces3 &&
          !properties?.loveDislove && (
            <div className="item">
              <strong>
                Ativar Não se aplica (NA){" "}
                <Tooltip
                  title="A opção N/A (Não se aplica) é usada para uma questão que o usuário não sabe responder, 
                ou a questão não se enquadra na experiência dele, 
                sendo assim ele pode ignorar a mesma, marcando essa opção"
                >
                  <span>
                    <PiQuestionBold />
                  </span>
                </Tooltip>{" "}
              </strong>
              <div className="content">
                <Flex style={{ width: "100%" }} justify="space-between">
                  <Button
                    type={naOptionLeft ? "primary" : "default"}
                    onClick={() => putNotApplyQuestion(!naOptionLeft, "left")}
                  >
                    <StepBackwardOutlined /> (N/A)
                  </Button>
                  <Button
                    type={naOptionRight ? "primary" : "default"}
                    onClick={() => putNotApplyQuestion(!naOptionRight, "right")}
                  >
                    (N/A) <StepForwardOutlined />
                  </Button>
                </Flex>
              </div>
            </div>
          )}

        {questionType === "checkbox" && (
          <div className="item">
            <strong>Máximo de opções que podem ser marcadas</strong>
            <div className="content">
              <Space wrap>
                <Select
                  defaultValue={maxLength}
                  style={{
                    width: 300,
                  }}
                  onChange={(val) => changeMaxLengthCheckbox(val)}
                  options={maxLengthCheckbox()}
                />
              </Space>
            </div>
          </div>
        )}

        {questionType === "auto-complete" && (
          <Space direction="vertical" style={{ width: "100%" }}>
            <TextAreaAutoComplete />
          </Space>
        )}

        {questionType === "optin" && (
          <div className="item">
            <div className="content">
              <Switch
                value={persistAnswer === "true"}
                onChange={() => enableOptin(persistAnswer)}
              />
              {` Habilitado por padrão `}
              <Tooltip title="Ao marcar essa opção, ao abrir uma pesquisa, a questão com OptIN já virá selecionada">
                <PiQuestionBold />
              </Tooltip>
            </div>
          </div>
        )}

        {questionType === "input" && (
          <>
            <div className="item">
              <strong>{renderInputMasks()["label"]}</strong>
              <div className="content">
                <Input
                  disabled={true}
                  size="large"
                  placeholder={renderInputMasks()["mask"]}
                />
              </div>
            </div>
            <div className="item">
              <strong>Máximos de caracteres permitidos</strong>
              <div className="content">
                <Switch
                  value={properties?.maxLength}
                  onChange={() => {
                    let questionData = getStorage("EDITION_" + surveyid, true);
                    if (properties?.maxLength) {
                      delete questionData.properties.maxLength;
                    } else {
                      questionData.properties.maxLength = inputProps(
                        questionData.properties
                      )["maxLength"];
                    }
                    setStorage("EDITION_" + surveyid, questionData, true);
                    dispatch(setUpdateX());
                  }}
                  disabled={customInput()}
                />
                {` Habilitar `}
                {properties?.maxLength && properties?.maxLength > 0 && (
                  <>
                    <InputNumber
                      defaultValue={properties?.maxLength || 250}
                      min={1}
                      max={250}
                      changeOnWheel
                      readOnly={customInput()}
                      disabled={customInput()}
                      style={{ width: "80px", marginLeft: 20 }}
                      onChange={(sval) => {
                        let questionData = getStorage(
                          "EDITION_" + surveyid,
                          true
                        );
                        if (sval > 0) {
                          questionData.properties.maxLength = sval;
                        } else {
                          delete questionData.properties.maxLength;
                        }
                        setStorage("EDITION_" + surveyid, questionData, true);
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          </>
        )}

        {questionType === "image-upload" && (
          <div className="item">
            <strong>Máximo de envios</strong>
            <div className="content">
              <Select
                defaultValue={properties.limit || 4}
                style={{ width: 120 }}
                onChange={(value) => {
                  let questionData = getStorage("EDITION_" + surveyid, true);
                  questionData.properties.limit = value;
                  setStorage("EDITION_" + surveyid, questionData, true);
                  dispatch(setUpdateX());
                }}
                options={[
                  { value: 4 },
                  { value: 5 },
                  { value: 6 },
                  { value: 7 },
                  { value: 8 },
                  { value: 9 },
                  { value: 10 },
                ]}
              />
            </div>
            <br />
            <strong>Tamanho máximo de cada imagem</strong>
            <div className="content">
              <Select
                defaultValue={properties.maxSize || 1024}
                style={{ width: 120 }}
                onChange={(value) => {
                  let questionData = getStorage("EDITION_" + surveyid, true);
                  questionData.properties.maxSize = value;
                  setStorage("EDITION_" + surveyid, questionData, true);
                  dispatch(setUpdateX());
                }}
                options={[
                  { value: 1024, label: "1mb" },
                  { value: 2048, label: "2mb" },
                  { value: 3072, label: "3mb" },
                  { value: 4096, label: "4mb" },
                  { value: 5120, label: "5mb" },
                  { value: 6144, label: "6mb" },
                  { value: 7168, label: "7mb" },
                  { value: 8192, label: "8mb" },
                  { value: 9216, label: "9mb" },
                  { value: 10240, label: "10mb" },
                ]}
              />
            </div>
            <br />
            <strong>Tipos de imagens</strong>
            <div className="content">
              <Select
                mode="multiple"
                allowClear
                defaultValue={
                  properties.acceptTypes || [
                    "image/png",
                    "image/jpg",
                    "image/jpeg",
                  ]
                }
                status={properties?.acceptTypes || "error"}
                style={{ width: "100%" }}
                onChange={(value) => {
                  if (value.length <= 0) {
                    messageApi.warning(
                      "Necessário ter pelo menos uma opção",
                      2
                    );
                  }

                  let questionData = getStorage("EDITION_" + surveyid, true);
                  questionData.properties.acceptTypes = value;
                  setStorage("EDITION_" + surveyid, questionData, true);
                  dispatch(setUpdateX());
                }}
                options={[
                  { value: "image/png", label: "PNG" },
                  { value: "image/jpg", label: "JPG" },
                  { value: "image/jpeg", label: "JPEG" },
                ]}
              />
            </div>
          </div>
        )}
      </>
    );
  };

  const TextareaContent = () => {
    const [text, setText] = useState(textTitle);
    const [openWysiwyg, setOpenWysiwyg] = useState(false);
    const [openCode, setOpenCode] = useState(false);

    const changeContextText = (value) => {
      let questionData = getStorage("EDITION_" + surveyid, true);
      let dataSurvey = getStorage("SURVEY_" + surveyid, true);

      dataSurvey.languageOptions.forEach((l) => {
        questionData.title[l.value] =
          questionType === "reset-button" ? value.toUpperCase() : value;
      });

      setStorage("EDITION_" + surveyid, questionData, true);
      setText(questionType === "reset-button" ? value.toUpperCase() : value);
    };

    const saveChanges = (changedText) => {
      changeContextText(changedText);
      setOpenWysiwyg(false);
    };

    const TextareaHTML = () => {
      const [htmlText, setHtmlText] = useState(text);

      const saveHTMLChanges = () => {
        changeContextText(htmlText);
        setOpenCode(false);
      };

      return (
        <>
          <TextArea
            rows={4}
            onChange={({ target: { value } }) => setHtmlText(value)}
            value={htmlText}
            style={{
              height: "400px",
              overflow: "hidden auto",
              resize: "none",
            }}
          />
          <div className="buttons-modal-no-footer">
            <Button type="default" onClick={() => setOpenCode(!openCode)}>
              Cancelar
            </Button>
            <Button type="primary" onClick={() => saveHTMLChanges()}>
              Salvar
            </Button>
          </div>
        </>
      );
    };

    return (
      <>
        {questionType === "reset-button" ? (
          <Input
            maxLength={30}
            onChange={({ target: { value } }) => changeContextText(value)}
            value={text}
          />
        ) : (
          <div className="html-preview">
            <div className="toolbar">
              <Button onClick={() => setOpenCode(!openCode)} type="default">
                <pre>&lt;code/&gt;</pre>
              </Button>
            </div>
            <div
              className="html"
              onClick={() => {
                setOpenWysiwyg(!openWysiwyg);
              }}
            >
              <Interweave content={text} />
            </div>
          </div>
        )}

        <Modal
          open={openWysiwyg}
          onCancel={() => setOpenWysiwyg(!openWysiwyg)}
          footer={null}
          title={i18n.EDIT}
          styles={{ body: { padding: "0px" } }}
          width={1000}
        >
          <Wysiwyg val={text} fnOK={saveChanges} fnCancel={setOpenWysiwyg} />
        </Modal>
        <Modal
          open={openCode}
          closeIcon={false}
          onCancel={() => {
            setOpenCode(false);
          }}
          footer={null}
          title="Código HTML"
          styles={{ body: { padding: "0px" } }}
          width={1000}
        >
          <TextareaHTML />
        </Modal>
      </>
    );
  };

  const GridQuestiontypes = () => {
    const gridTypes = [
      {
        label: "Básicos",
        items: {
          text: "Texto",
          radiobox: "Radiobox",
          checkbox: "Checkbox",
          input: "Input",
          dropdown: "Dropdown",
          textarea: "Campo Aberto",
        },
      },
      {
        label: "Intermediários",
        items: {
          nps: "NPS",
          csat: "CSAT",
          ces: "CES",

          optin: "Opt IN",
          "language-dropdown": "Seletor de Idioma",
          "auto-complete": "Auto Completar",
          "image-upload": "Carregador de Imagens",
          "reset-button": "Botão Resetar",
        },
      },
      {
        label: "Avançados",
        items: {
          mosaic: "Mosaico",
          "cascading-dropdown": "Dropdown Cascata",
        },
      },
    ];

    let returnItems = (el) => {
      let cardGrid = [];

      for (let index in el) {
        cardGrid.push(
          <Card.Grid
            key={`grid-items-${index}`}
            onClick={() => {
              if (
                [
                  "text",
                  "radiobox",
                  "checkbox",
                  "dropdown",
                  "teaxarea",
                  "input",
                  "nps",
                  "csat",
                  "auto-complete",
                  "language-dropdown",
                  "optin",
                  "textarea",
                  "reset-button",
                  "image-upload",
                ].includes(index)
              ) {
                selectQuestionType(index);
                setOpenModalQuestionTypes(!openModalQuestionTypes);
              } else {
                messageApi.info("Recurso de desenvolvimento!", 2);
              }
            }}
            style={{ padding: "5px 15px", cursor: "pointer" }}
          >
            {el[index]}
          </Card.Grid>
        );
      }

      return cardGrid;
    };

    return (
      <Space direction="vertical" style={{ width: "100%" }}>
        {gridTypes.map((section, i) => {
          return (
            <Card
              title={section.label}
              key={`card-items-${i}`}
              styles={{ header: { backgroundColor: "#eee" } }}
            >
              {returnItems(section.items)}
            </Card>
          );
        })}
      </Space>
    );
  };

  const putRequireQuestion = (value) => {
    let questionData = getStorage("EDITION_" + surveyid, true);
    questionData.require = value;
    setStorage("EDITION_" + surveyid, questionData, true);
    setRequire(value);
    dispatch(setUpdateX());
  };

  const changeQuestionStatus = (value) => {
    let questionData = getStorage("EDITION_" + surveyid, true);
    questionData.active = value;
    setStorage("EDITION_" + surveyid, questionData, true);
    setQuestionActive(value);
    dispatch(setUpdateX());
  };

  const putNotApplyQuestion = (value, side) => {
    let questionData = getStorage("EDITION_" + surveyid, true);
    if (side === "right") {
      if (value) {
        questionData.properties.naOptionRight = value;

        if (questionData.properties?.naOptionLeft) {
          delete questionData.properties.naOptionLeft;
        }
      } else {
        delete questionData.properties.naOptionRight;
      }
      setNaOptionRight(value);
    } else {
      if (value) {
        questionData.properties.naOptionLeft = value;

        if (questionData.properties?.naOptionRight) {
          delete questionData.properties.naOptionRight;
        }
      } else {
        delete questionData.properties.naOptionLeft;
      }
      setNaOptionLeft(value);
    }

    setStorage("EDITION_" + surveyid, questionData, true);
    dispatch(setUpdateX());
  };

  const enableOptin = (value) => {
    let questionData = getStorage("EDITION_" + surveyid, true);
    questionData.properties.persistAnswer = value === "true" ? "false" : "true";
    setStorage("EDITION_" + surveyid, questionData, true);
    setPersistAnswer(value);
    dispatch(setUpdateX());
  };

  const maxLengthCheckbox = () => {
    let maxOpts = [
      {
        value: "*",
        label: "Não limitar",
      },
    ];

    if (settings !== null) {
      questionOptions.forEach((o, i) => {
        maxOpts.push({
          value: i + 1,
          label: i + 1,
        });
      });
    }

    return maxOpts;
  };

  const changeMaxLengthCheckbox = (val) => {
    let questionData = getStorage("EDITION_" + surveyid, true);

    if (val !== "*") {
      questionData.properties.maxLength = val;
    } else {
      delete questionData.properties.maxLength;
    }

    setStorage("EDITION_" + surveyid, questionData, true);
    setMaxLength(val);
  };

  return (
    <>
      {contextHolder}
      {settings !== null && (
        <>
          <div className="item">
            <strong>Tipo de Questão</strong>
            <div className="content">
              <div
                className="question-editor"
                onClick={() =>
                  setOpenModalQuestionTypes(!openModalQuestionTypes)
                }
              >
                {i18n.QUESTION_TYPES[questionType]}
              </div>
              {questionType === "input" && customInput() && (
                <Badge
                  style={{ marginTop: 5 }}
                  color="#aaa"
                  count={inputProps(settings.properties)["label"]}
                />
              )}
            </div>
          </div>

          <div className="item">
            <strong>
              {questionType === "reset-button"
                ? "Texto do botão"
                : "O que você quer escrever ou perguntar?"}
            </strong>
            <div className="content">
              <TextareaContent />
            </div>
          </div>

          {!["text", "optin", "reset-button"].includes(settings.type) && (
            <div className="item">
              <div className="content">
                <Switch
                  value={require}
                  onChange={() => putRequireQuestion(!require)}
                />{" "}
                Questão obrigatória
              </div>
            </div>
          )}

          <BuildOptions />
          <BuildParams />

          <div className="item">
            <div className="content">
              <Switch
                value={questionActive}
                onChange={() => changeQuestionStatus(!questionActive)}
              />{" "}
              Questão ativa
            </div>
          </div>

          <Modal
            title="Tipo da Questão"
            open={openModalQuestionTypes}
            onCancel={() => setOpenModalQuestionTypes(!openModalQuestionTypes)}
            width={1000}
            footer={null}
            styles={{ body: { padding: "0px" } }}
            style={{ padding: "0px" }}
          >
            <GridQuestiontypes />
          </Modal>

          <Modal
            title="Colar"
            open={openPastInRadiobox || openPastInCheckbox}
            onCancel={() => {
              switch (questionType) {
                case "radiobox":
                  setOpenPastInRadiobox(false);
                  break;
                case "checkbox":
                  setOpenPastInCheckbox(false);
                  break;
                default:
                  break;
              }
            }}
            styles={{ body: { padding: "0px" } }}
            style={{ padding: "0px" }}
            footer={null}
          >
            <TextAreaRadioboxCheckbox />
          </Modal>

          <Modal
            title="Importar opções"
            open={
              openModalImportOptions ||
              openImportAutoComplete ||
              openImportRadiobox ||
              openImportCheckbox
            }
            onCancel={() => {
              switch (questionType) {
                case "dropdown":
                  setOpenModalImportOptions(!openModalImportOptions);
                  break;
                case "auto-complete":
                  setOpenImportAutoComplete(!openImportAutoComplete);
                  break;
                case "radiobox":
                  setOpenImportRadiobox(!openImportRadiobox);
                  break;
                case "checkbox":
                  setOpenImportCheckbox(!openImportCheckbox);
                  break;
                default:
                  break;
              }
            }}
            styles={{ body: { padding: "0px" } }}
            style={{ padding: "0px" }}
            footer={null}
          >
            <ImportContent />
          </Modal>
        </>
      )}
    </>
  );
};

export default AbaQuestion;

import React from "react";
import { Interweave } from "interweave";

//styles
import "../../../../assets/styles/builder/plain-text.css";

const PlainTextComponent = (props) => {
  const { data, language } = props.data;

  return (
    <div id="plain-text-component">
      <div className="plain-text-content">
        {data.title && data.title !== "" && (
          <Interweave content={data.title[language]} />
        )}
      </div>
    </div>
  );
};

export default PlainTextComponent;

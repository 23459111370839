import React, { useState, useEffect } from "react";
import { Button, Select, Alert, Input, Modal, Collapse, Badge } from "antd";
import { useSelector } from "react-redux";
import {
  DeleteOutlined,
  PlusOutlined,
  FileUnknownOutlined,
} from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { Interweave } from "interweave";
import i18n from "../../../utils/i18n";

//utils
import { getStorage, setStorage } from "../../../utils/Storage";
import { logicGroups } from "../../../utils/Objects";

//redux
import { windowWidthRedux } from "../../../store/application/app.slice";

const QuestionLogics = ({ questionData }) => {
  const { surveyid } = useParams();
  const qLogic = getStorage("LOGIC_" + surveyid, true);
  const surveyData = getStorage("SURVEY_" + surveyid, true);

  const [logicDisplay, setLogicDisplay] = useState(qLogic !== "" ? qLogic : []);
  const [questionsToShow, setQuestionsToShow] = useState([]);
  const [openTuto, setOpenTuto] = useState(false);

  //redux state
  const wScreen = useSelector(windowWidthRedux);

  useEffect(() => {
    let questions = [];
    surveyData.pages.forEach((page, pageIndex) => {
      if (pageIndex <= questionData.pagePosition) {
        page.questions.forEach((question, questionIndex) => {
          if (
            !["text", "reset-button", "image-upload", "action"].includes(
              question.type
            )
          ) {
            /*se estiver na página da questão que está sendo inserida lógica
            * só pode inserir questões anteriores a ela, nunca posterior.
            Do contrário, pode inserir normal na lista de questões.  */
            if (questionData.pagePosition === pageIndex) {
              if (questionIndex < questionData.position && question.active) {
                questions.push({
                  value: question.id,
                  label: `#${question.id} - ${
                    question.title[surveyData.language]
                  }`,
                });
              }
            } else {
              questions.push({
                value: question.id,
                label: `#${question.id} - ${
                  question.title[surveyData.language]
                }`,
              });
            }
          }
        });
      }
    });
    setQuestionsToShow(questions);
    // eslint-disable-next-line
  }, []);

  const mountLogic = () => {
    const qDataJSON = getStorage("EDITION_" + surveyid, true);

    let isURL = false;

    /* Se for a primeira questão, não pode ter lógica de questão.
     * Caso contrário pode seguir o fluxo normal*/
    if (
      surveyData.pages[0].questions[0].id === qDataJSON.id ||
      questionsToShow.length === 0
    ) {
      isURL = true;
    }

    const operators = isURL ? logicGroups("url", null) : null;

    return {
      element_1: {
        type: isURL ? "url" : "responses",
        value: isURL ? "" : 0,
      },
      element_2: {
        type: "response",
        value: isURL ? "" : [],
      },
      lvl: 0,
      operator: isURL ? operators[0].value : operators,
    };
  };

  const findoQuestionData = (qId) => {
    let q = null;
    surveyData.pages.forEach((page) => {
      page.questions.forEach((question) => {
        if (question.id === qId) {
          q = question;
        }
      });
    });
    return q;
  };

  const addLogicCondition = (groupIndex) => {
    const newLvl0 = mountLogic();
    let newQuestionLogic = [...logicDisplay];
    newQuestionLogic[groupIndex].push("and");
    newQuestionLogic[groupIndex].push(newLvl0);
    setLogicDisplay(newQuestionLogic);
    setStorage("LOGIC_" + surveyid, newQuestionLogic, true);
  };

  const deleteLogicCondition = (groupIndex, logicIndex) => {
    let logicDisplayCopy = [...logicDisplay];

    //busca pela lógica e deleta a mesma e seu respectivo operador
    let logicDisplayFiltred = logicDisplayCopy.map((groupLogic, iGroup) => {
      if (iGroup === groupIndex) {
        if (groupLogic.length === 1) {
          return null;
        } else {
          if (iGroup === 0 && logicIndex === 0) {
            delete groupLogic[logicIndex];
            delete groupLogic[logicIndex + 1];
          } else {
            delete groupLogic[logicIndex];
            delete groupLogic[logicIndex - 1];
          }

          return groupLogic.filter((l) => l !== null);
        }
      } else {
        //devolve o grupo que não está sendo tratado
        return groupLogic;
      }
    });

    /*
     * Higienização de grupos vazios, removendo os opreadores respectivos
     * O filtro busca operadores antes ou depois de grupos nulos e faz as conversões desses valores para null.
     * Exemplo:
     * [null,operator,grupo]=>[null,null,grupo] OU
     * [grupo,operator,null]=>[grupo,null,null]
     */
    let positionToNull = null; //define posição de um array para deletar
    let logicDisplayFiltredGroups = [];
    logicDisplayFiltred.forEach((group, index) => {
      if (index === 0) {
        if (group === null) {
          positionToNull = index + 1;
        }
      } else {
        if (positionToNull !== null && index === positionToNull) {
          group = null;
          positionToNull = null;
        } else {
          if (group === null) {
            delete logicDisplayFiltredGroups[index - 1];
          }
        }
      }
      logicDisplayFiltredGroups.push(group);
    });

    //remove os valores vazios dos grupos que foram higienizados
    logicDisplayCopy = logicDisplayFiltredGroups.filter((h) => h !== null);

    //salvando dados dos grupos atualziados
    setLogicDisplay(logicDisplayCopy);
    setStorage(
      "LOGIC_" + surveyid,
      logicDisplayCopy.length > 0 ? JSON.stringify(logicDisplayCopy) : ""
    );
  };

  const addGroup = () => {
    const newLvl0 = mountLogic();
    let newQuestionLogic = [...logicDisplay];
    if (logicDisplay.length > 0) {
      newQuestionLogic.push("and");
      newQuestionLogic.push([newLvl0]);
      setLogicDisplay(newQuestionLogic);
      setStorage("LOGIC_" + surveyid, newQuestionLogic, true);
    } else {
      setLogicDisplay([[newLvl0]]);
      setStorage("LOGIC_" + surveyid, [[newLvl0]], true);
    }
  };

  const deleteGroup = (groupIndex) => {
    let logicDisplayCopy = [...logicDisplay];
    let newLogicDisplay = [];

    if (groupIndex === 0) {
      delete logicDisplayCopy[groupIndex];
      delete logicDisplayCopy[groupIndex + 1];
    } else {
      delete logicDisplayCopy[groupIndex];
      delete logicDisplayCopy[groupIndex - 1];
    }

    newLogicDisplay = logicDisplayCopy.filter((group) => group !== null);

    setLogicDisplay(newLogicDisplay);
    setStorage("LOGIC_" + surveyid, newLogicDisplay, true);
  };

  const changeOperatorGroup = (groupIndex, operator) => {
    let logicDisplayCopy = [...logicDisplay];
    logicDisplayCopy[groupIndex] = operator;
    setLogicDisplay(logicDisplayCopy);
    setStorage("LOGIC_" + surveyid, logicDisplayCopy, true);
  };

  const changeOperatorLogic = (groupIndex, operator, logicIndex) => {
    let logicDisplayCopy = [...logicDisplay];
    logicDisplayCopy[groupIndex][logicIndex] = operator;
    setLogicDisplay(logicDisplayCopy);
    setStorage("LOGIC_" + surveyid, logicDisplayCopy, true);
  };

  const clearAllLogics = () => {
    setLogicDisplay([]);
    setStorage("LOGIC_" + surveyid, "");
  };

  const CreateLvlZero = ({ data, groupIndex, logicIndex }) => {
    let logicDisplayCopy = [...logicDisplay];

    //tipo de analisador | [questão, url ou contato]
    let e1Type = data.element_1.type;

    //valor padrão se existir
    let e1Value = data.element_1.value;

    //operador da questão
    let oper = data.operator;

    //valor dinâmico ao tipo de questão
    let e2Value = data.element_2.value;

    //dados da questão selecionada
    let dynamicQData =
      e1Value !== 0 && e1Value !== "" ? findoQuestionData(e1Value) : null;

    const questionsList = [].concat(
      {
        value: 0,
        label: i18n.BUILD_MSG_3,
      },
      questionsToShow
    );

    let logicOptionBase =
      questionsToShow.length > 0
        ? [
            { value: "responses", label: i18n.QUESTION },
            { value: "url", label: "URL" },
          ]
        : [{ value: "url", label: "URL" }];

    let logicOptionOperators =
      e1Type === "responses"
        ? dynamicQData !== null
          ? logicGroups(e1Type, dynamicQData.type)
          : []
        : logicGroups(e1Type, null);

    const changeOptions = (value, action) => {
      let qLogicJSON = getStorage("LOGIC_" + surveyid, true);
      let qLogicTarget = qLogicJSON[groupIndex][logicIndex];

      if (action === "type") {
        const operators = value === "url" ? logicGroups("url", null) : null;

        qLogicTarget.element_1.type = value;
        qLogicTarget.element_1.value = value === "url" ? "" : 0;
        qLogicTarget.element_2.value = value === "url" ? "" : [];
        qLogicTarget.operator = operators;
      }

      if (action === "question") {
        const questionTarget = findoQuestionData(value);
        const operators =
          value !== 0 ? logicGroups("responses", questionTarget.type) : null;

        qLogicTarget.element_1.value = value;
        qLogicTarget.element_2.value = [
          "input",
          "textarea",
          "auto-complete",
          "optin",
          "nps",
          "csat",
          "radiobox",
        ].includes(questionTarget.type)
          ? ""
          : [];
        qLogicTarget.operator = operators !== null ? operators[0].value : null;
      }

      if (action === "operator") {
        qLogicTarget.operator = value;

        let newValue2 = [];

        if (!["equal", "not-equal", "in", "not-in"].includes(value)) {
          newValue2 = "";
        }

        qLogicTarget.element_2.value = newValue2;
      }

      qLogicJSON[groupIndex][logicIndex] = qLogicTarget;
      setStorage("LOGIC_" + surveyid, qLogicJSON, true);
      setLogicDisplay(qLogicJSON);
    };

    const DynamicLayoutValues = ({ questData }) => {
      let qLogicJSON = getStorage("LOGIC_" + surveyid, true);
      let qLogicTarget =
        qLogicJSON !== "" ? qLogicJSON[groupIndex][logicIndex] : qLogicJSON;

      const NPS = () => {
        const setNPSValue = (val) => {
          let e2ValueJSON = qLogicTarget.element_2.value;

          if (
            ["euqal", "nor-equal", "in", "not-in"].includes(
              qLogicTarget.operator
            )
          ) {
            //se existir deleta, caso contrário adiciona
            if (e2ValueJSON.includes(val)) {
              e2ValueJSON = e2ValueJSON.filter((v) => v !== val);
            } else {
              e2ValueJSON.push(val);
            }
          } else {
            e2ValueJSON = val;
          }

          qLogicTarget.element_2.value = e2ValueJSON;
          qLogicJSON[groupIndex][logicIndex] = qLogicTarget;
          setStorage("LOGIC_" + surveyid, qLogicJSON, true);
          setLogicDisplay(qLogicJSON);
        };

        return (
          !["answered", "not-answered"].includes(oper) && (
            <div
              className={`logic-layout-nps ${
                wScreen > 600 ? "compact" : "block"
              }`}
            >
              {questData.options.map((opt, i) => {
                return (
                  <div
                    className={`item ${
                      Array.isArray(e2Value)
                        ? e2Value.includes(opt)
                          ? "active"
                          : ""
                        : e2Value === opt
                        ? "active"
                        : ""
                    }`}
                    key={"logic-layout-nps" + i}
                    onClick={() => setNPSValue(opt)}
                  >
                    {opt}
                  </div>
                );
              })}
            </div>
          )
        );
      };

      const CSAT = () => {
        let e2ValueJSON = qLogicTarget.element_2.value;

        const setCSATValue = (val) => {
          if (["in", "not-in"].includes(oper)) {
            //se existir deleta, caso contrário adiciona
            if (e2ValueJSON.includes(val)) {
              e2ValueJSON = e2ValueJSON.filter((v) => v !== val);
            } else {
              e2ValueJSON.push(val);
            }
          } else {
            e2ValueJSON = val;
          }

          qLogicTarget.element_2.value = e2ValueJSON;
          qLogicJSON[groupIndex][logicIndex] = qLogicTarget;
          setStorage("LOGIC_" + surveyid, qLogicJSON, true);
          setLogicDisplay(qLogicJSON);
        };

        return (
          !["answered", "not-answered"].includes(oper) && (
            <div
              className={`logic-layout-csat ${
                wScreen > 600 ? "compact" : "block"
              }`}
            >
              {questData.options.map((opt, i) => {
                return (
                  <div
                    className={`item ${
                      Array.isArray(e2Value)
                        ? e2Value.includes(opt)
                          ? "active"
                          : ""
                        : e2Value === opt
                        ? "active"
                        : ""
                    }`}
                    key={"logic-layout-csat" + i}
                    onClick={() => setCSATValue(opt)}
                  >
                    {opt}
                  </div>
                );
              })}
            </div>
          )
        );
      };

      const Radiobox = () => {
        const setRadioboxValue = (val) => {
          if (["in", "not-in"].includes(oper)) {
            e2Value = Array.isArray(e2Value) ? e2Value : [e2Value];

            //se existir deleta, caso contrário adiciona
            if (e2Value.includes(val)) {
              e2Value = e2Value.filter((v) => v !== val);
            } else {
              e2Value.push(val);
            }
          } else {
            e2Value = val;
          }

          data.element_2.value = e2Value;
          logicDisplayCopy[groupIndex][logicIndex] = data;
          setStorage("LOGIC_" + surveyid, logicDisplayCopy, true);
          setLogicDisplay(logicDisplayCopy);
        };

        let selectOptions = questData.options.map((o) => {
          return {
            value: o.value,
            label: o.label[surveyData.language],
          };
        });

        selectOptions.unshift({
          value: 0,
          label: i18n.BUILD_MSG_4,
        });

        return (
          !["answered", "not-answered"].includes(oper) && (
            <div
              className={`logic-layout-radiobox ${
                wScreen > 600 ? "compact" : "block"
              }`}
            >
              {questData.options.length < 10 &&
              ["in", "not-in"].includes(oper) ? (
                questData.options.map((opt, i) => {
                  return (
                    <div
                      key={"logic-layout-radiobox" + i}
                      onClick={() => setRadioboxValue(opt.value)}
                      className={`item ${
                        Array.isArray(e2Value)
                          ? e2Value.includes(opt.value)
                            ? "active"
                            : ""
                          : e2Value === opt.value
                          ? "active"
                          : ""
                      }`}
                    >
                      {opt.label[surveyData.language]}
                    </div>
                  );
                })
              ) : (
                <Select
                  style={{ width: "100%" }}
                  defaultValue={e2Value}
                  options={selectOptions}
                  onChange={(value) => setRadioboxValue(value, "type")}
                />
              )}
            </div>
          )
        );
      };

      const Checkbox = () => {
        const setCheckboxValue = (val) => {
          if (["in", "not-in"].includes(oper)) {
            e2Value = Array.isArray(e2Value) ? e2Value : [e2Value];

            //se existir deleta, caso contrário adiciona
            if (e2Value.includes(val)) {
              e2Value = e2Value.filter((v) => v !== val);
            } else {
              e2Value.push(val);
            }
          } else {
            e2Value = val;
          }

          data.element_2.value = e2Value;
          logicDisplayCopy[groupIndex][logicIndex] = data;
          setStorage("LOGIC_" + surveyid, logicDisplayCopy, true);
          setLogicDisplay(logicDisplayCopy);
        };

        let selectOptions = questData.options.map((o) => {
          return {
            value: o.value,
            label: o.label[surveyData.language],
          };
        });

        return (
          !["answered", "not-answered"].includes(oper) && (
            <div
              className={`logic-layout-checkbox ${
                wScreen > 600 ? "compact" : "block"
              }`}
            >
              {questData.options.length < 10 &&
              ["in", "not-in"].includes(oper) ? (
                questData.options.map((opt, i) => {
                  return (
                    <div
                      key={"logic-layout-checkbox" + i}
                      onClick={() => setCheckboxValue(opt.value)}
                      className={`item ${
                        Array.isArray(e2Value)
                          ? e2Value.includes(opt.value)
                            ? "active"
                            : ""
                          : e2Value === opt.value
                          ? "active"
                          : ""
                      }`}
                    >
                      {opt.label[surveyData.language]}
                    </div>
                  );
                })
              ) : (
                <Select
                  style={{ width: "100%" }}
                  mode="multiple"
                  allowClear
                  defaultValue={e2Value}
                  options={selectOptions}
                  onChange={(value) => setCheckboxValue(value, "type")}
                />
              )}
            </div>
          )
        );
      };

      const Inputs = () => {
        const changeInputValue = (val) => {
          data.element_2.value = e2Value = val;
          logicDisplayCopy[groupIndex][logicIndex] = data;
          setStorage("LOGIC_" + surveyid, logicDisplayCopy, true);
          //setLogicDisplay(logicDisplayCopy);
        };

        return (
          !["answered", "not-answered"].includes(oper) && (
            <div
              className={`logic-layout-inputs ${
                wScreen > 600 ? "compact" : "block"
              }`}
            >
              <Input
                onChange={({ target: { value } }) => changeInputValue(value)}
                style={{ width: "100%" }}
              />
            </div>
          )
        );
      };

      const Optin = () => {
        let e2ValueJSON = qLogicTarget.element_2.value;

        const setOptinValue = (val) => {
          e2ValueJSON = val;
          qLogicTarget.element_2.value = e2ValueJSON;
          qLogicJSON[groupIndex][logicIndex] = qLogicTarget;
          setStorage("LOGIC_" + surveyid, qLogicJSON, true);
          setLogicDisplay(qLogicJSON);
        };

        return (
          !["answered", "not-answered"].includes(oper) && (
            <div
              className={`logic-layout-optin ${
                wScreen > 600 ? "compact" : "block"
              }`}
            >
              {[
                { label: i18n.MARKED, value: "true" },
                { label: i18n.UNMARKED, value: "false" },
              ].map((opt, i) => {
                return (
                  <div
                    className={`item ${e2Value === opt.value ? "active" : ""}`}
                    key={"logic-layout-optin" + i}
                    onClick={() => setOptinValue(opt.value)}
                  >
                    {opt.label}
                  </div>
                );
              })}
            </div>
          )
        );
      };

      if (questData !== null) {
        switch (questData.type) {
          case "nps":
            return <NPS />;
          case "csat":
            return <CSAT />;
          case "radiobox":
            return <Radiobox />;
          case "checkbox":
            return <Checkbox />;
          case "input":
          case "textarea":
          case "auto-complete":
            return <Inputs />;
          case "optin":
            return <Optin />;
          default:
            return <></>;
        }
      }

      return <></>;
    };

    const InputUrl = ({ type }) => {
      const [urlName, setUrlName] = useState(data.element_1.value);
      const [urlValue, setUrlValue] = useState(data.element_2.value);

      const changeValue = (value) => {
        if (type === "name") {
          setUrlName(value);
          data.element_1.value = value;
        } else {
          setUrlValue(value);
          data.element_2.value = value;
        }

        logicDisplayCopy[groupIndex][logicIndex] = data;
        setStorage("LOGIC_" + surveyid, logicDisplayCopy, true);
      };

      return (
        <Input
          style={{
            width: wScreen > 600 ? "70%" : "100%",
          }}
          value={type === "name" ? urlName : urlValue}
          onChange={({ target: { value } }) => changeValue(value)}
          placeholder={type === "name" ? i18n.BUILD_MSG_5 : i18n.BUILD_MSG_6}
        />
      );
    };

    return (
      <div className="logic-condition-card">
        <div
          className={`logic-condition-card-row ${
            wScreen > 600 ? "compact" : "block"
          }`}
        >
          <Select
            style={{
              width: wScreen > 600 ? "30%" : "100%",
            }}
            defaultValue={e1Type}
            options={logicOptionBase}
            onChange={(value) => changeOptions(value, "type")}
          ></Select>
          {e1Type === "responses" ? (
            <Select
              style={{
                width: wScreen > 600 ? "70%" : "100%",
              }}
              value={e1Value}
              options={questionsList}
              optionRender={(opt) => (
                <Interweave
                  content={opt.data.label}
                  blockList="span"
                  noHtml={true}
                />
              )}
              onChange={(value) => changeOptions(value, "question")}
            ></Select>
          ) : (
            <InputUrl type={"name"} />
          )}
        </div>
        <div
          className={`logic-condition-card-row ${
            wScreen > 600 ? "compact" : "block"
          }`}
        >
          {e1Value !== 0 && (
            <Select
              style={{
                width: wScreen > 600 ? "30%" : "100%",
              }}
              defaultValue={oper}
              onChange={(value) => changeOptions(value, "operator")}
              options={logicOptionOperators}
            ></Select>
          )}
          {e1Type === "responses" ? (
            <DynamicLayoutValues questData={dynamicQData} />
          ) : (
            <InputUrl type={"value"} />
          )}
        </div>
        <div
          className={`logic-condition-card-row btn-rows ${
            wScreen > 600 ? "compact" : "block"
          }`}
        >
          <DeleteOutlined
            className="delete-condition"
            style={{ fontSize: "14px" }}
            onClick={() => deleteLogicCondition(groupIndex, logicIndex)}
          />
        </div>
      </div>
    );
  };

  const CreateLvlOne = ({ data, groupIndex }) => {
    return (
      <>
        {data.map((item, logicIndex) => (
          <div
            className={`${
              logicIndex % 2 === 0
                ? "logic-group-card"
                : "logic-group-card-operator"
            }`}
            key={`logic-group-card` + logicIndex}
          >
            {logicIndex % 2 === 0 ? (
              <CreateLvlZero
                data={item}
                groupIndex={groupIndex}
                logicIndex={logicIndex}
                key={`logic-group-card` + logicIndex}
              />
            ) : (
              <div
                className="conditions-operator"
                key={`logic-group-card` + logicIndex}
              >
                <Select
                  style={{
                    width: "80px",
                  }}
                  defaultValue={item}
                  options={[
                    { value: "or", label: "OR" },
                    { value: "and", label: "AND" },
                  ]}
                  onChange={(value) =>
                    changeOperatorLogic(groupIndex, value, logicIndex)
                  }
                ></Select>
              </div>
            )}
          </div>
        ))}
        <Button
          onClick={() => addLogicCondition(groupIndex)}
          className="add-condition"
          icon={<PlusOutlined style={{ fontSize: "14px" }} />}
          style={{ float: "right", marginTop: "10px" }}
        >
          {i18n.LOGIC}
        </Button>
      </>
    );
  };

  const CreateLvlTwo = ({ data }) => {
    return data.map((item, groupIndex) => {
      return (
        <div
          className={`${
            groupIndex % 2 === 0 ? "group-card" : "group-operator"
          } ${data.length > 1 ? "padding" : ""}`}
          key={`group-card` + groupIndex}
        >
          {data.length > 1 && groupIndex % 2 === 0 && (
            <>
              <span>{i18n.GROUP}</span>{" "}
              <DeleteOutlined
                style={{ fontSize: "14px", cursor: "pointer" }}
                onClick={() => deleteGroup(groupIndex)}
              />
            </>
          )}
          {groupIndex % 2 === 0 ? (
            <CreateLvlOne data={item.slice()} groupIndex={groupIndex} />
          ) : (
            <Select
              key={groupIndex}
              style={{
                width: "80px",
                margin: "5px 0",
              }}
              defaultValue={item}
              options={[
                { value: "or", label: "OR" },
                { value: "and", label: "AND" },
              ]}
              onChange={(value) => changeOperatorGroup(groupIndex, value)}
            ></Select>
          )}
        </div>
      );
    });
  };

  return (
    <div id="build-page-logics">
      {logicDisplay.length !== 0 && (
        <div className="btn-clear-logics">
          <Button
            onClick={() => setOpenTuto(!openTuto)}
            style={{ margin: "10px 10px 0 0" }}
            icon={<FileUnknownOutlined style={{ fontSize: "14px" }} />}
          >
            Tutorial
          </Button>
          <Button
            onClick={() => clearAllLogics()}
            style={{ marginTop: "10px" }}
            icon={<DeleteOutlined style={{ fontSize: "14px" }} />}
          >
            {i18n.CLEAR}
          </Button>
        </div>
      )}

      {logicDisplay.length !== 0 && <CreateLvlTwo data={logicDisplay} />}
      <div className="logic-group-card-btn">
        {logicDisplay.length === 0 && (
          <Alert message={i18n.BUILD_MSG_7} type="warning" showIcon />
        )}
        <Button
          onClick={() => addGroup()}
          className="add-logic-btn"
          style={{ marginTop: "10px" }}
          icon={<PlusOutlined style={{ fontSize: "14px" }} />}
        >
          {logicDisplay.length === 0 ? "Lógica" : "Grupo"}
        </Button>
      </div>
      <Modal
        open={openTuto}
        onOk={() => setOpenTuto(!openTuto)}
        onCancel={() => setOpenTuto(!openTuto)}
        cancelButtonProps={{ style: { display: "none" } }}
        title="Instruções Lógicas"
      >
        <Collapse
          accordion
          items={[
            {
              key: "1",
              label: <h4>É igual a</h4>,
              children: (
                <>
                  <p>
                    É preciso que a opção marcada ou texto seja exatamente
                    idêntico.
                  </p>
                  <p>
                    <strong>
                      Ex.: <Badge count="A = A (Verdadeiro)" color="green" />
                      <Badge count="A = B (Falso)" color="red" />
                    </strong>
                  </p>
                  <br />
                  <p>
                    Em casos de múltiplos valores, as respostas precisam ser
                    iguais a todas opções definidas.
                  </p>
                  <p>
                    <strong>
                      Ex.:{" "}
                      <Badge count="[A,B] = [A,B] (Verdadeiro)" color="green" />
                      <Badge count="[A,B] = [A,C] (Falso)" color="red" />
                    </strong>
                  </p>
                  <br />
                  <p>Funciona com Textos e Números.</p>
                </>
              ),
            },
            {
              key: "2",
              label: <h4>É diferente de</h4>,
              children: (
                <>
                  <p>
                    É preciso que a opção marcada ou texto seja exatamente
                    diferente.
                  </p>
                  <p>
                    <strong>
                      Ex.: <Badge count="A &ne; B (Verdadeiro)" color="green" />
                      <Badge count="A &ne; A (Falso)" color="red" />
                    </strong>
                  </p>
                  <br />
                  <p>
                    Em casos de múltiplos valores, as respostas precisam ser
                    diferentes em todas opções definidas.
                  </p>
                  <p>
                    <strong>
                      Ex.:{" "}
                      <Badge
                        count="[A,B] &ne; [A,C] (Verdadeiro)"
                        color="green"
                      />
                      <Badge count="[A,B] &ne; [A,B] (Falso)" color="red" />
                    </strong>
                  </p>
                  <br />
                  <p>Funciona com Textos e Números</p>
                </>
              ),
            },
            {
              key: "3",
              label: "Breve",
              children: <p>Demais explicações em breve</p>,
            },
          ]}
        />
      </Modal>
    </div>
  );
};

export default QuestionLogics;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Input, Switch, Form } from "antd";

//utils
import { getStorage, setStorage } from "../../../utils/Storage";
import i18n from "../../../utils/i18n";

const AbaPage = () => {
  const { surveyid } = useParams();

  const [settings, setSettings] = useState(null); //dado completo da página
  const [pageName, setPageName] = useState("");
  const [pageActive, setPageActive] = useState(true);

  const setLocalData = () => {
    let pData = getStorage("EDITION_PAGE_" + surveyid, true);

    setPageName(pData.name);
    setPageActive(pData.active);
    setSettings(pData);
  };

  useEffect(() => {
    setLocalData();
    // eslint-disable-next-line
  }, []);

  const change = (value, type) => {
    let jsonDataCopy = getStorage("EDITION_PAGE_" + surveyid, true);

    if (type === "name") {
      setPageName(value);
      jsonDataCopy.name = value;
    }
    if (type === "status") {
      setPageActive(value);
      jsonDataCopy.active = value;
    }

    setStorage("EDITION_PAGE_" + surveyid, jsonDataCopy, true);
  };

  return (
    <>
      {settings !== null && (
        <Form name="basic" autoComplete="off" layout="vertical">
          <Form.Item label={i18n.NAME} name="pagename" initialValue={pageName}>
            <Input
              value={pageName}
              onChange={({ target: { value } }) => change(value, "name")}
            />
          </Form.Item>

          <Form.Item label={i18n.ACTIVE_PAGE} name="activepage">
            <Switch
              checked={pageActive}
              onChange={() => change(!pageActive, "status")}
            />
          </Form.Item>
        </Form>
      )}
    </>
  );
};

export default AbaPage;

import i18n from "../i18n";

/**
 * Gera uma string aleatoria
 * @param {string} qtd - quantidade de caracteres a serem retornados
 * @returns
 */
export const randomId = (qtd) => {
  qtd = qtd || 10;

  var result = "";
  var characters = `
    ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789
    ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789
    ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789
    ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789
    ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789`;
  var charactersLength = characters.length;
  for (var i = 0; i < qtd; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

/**
 * Retorna o tipos de questões em formuato modular e categorizados
 * @returns {[]}
 */
export const questionTypesList = () => {
  return [
    {
      label: "Básicos",
      items: {
        text: "Texto",
        radiobox: "Radiobox",
        checkbox: "Checkbox",
        input: "Input",
        dropdown: "Dropdown",
        textarea: "Campo Aberto",
      },
    },
    {
      label: "Intermediários",
      items: {
        nps: "NPS",
        csat: "CSAT",
        ces: "CES",
        optin: "Opt IN",
        "language-dropdown": "Seletor de Idioma",
        "auto-complete": "Auto Completar",
        "image-upload": "Upload de Imagens",
        "reset-button": "Reiniciar Pesquisa (Botão)",
      },
    },
    {
      label: "Avançados",
      items: {
        mosaic: "Mosaico",
        "cascading-dropdown": "Dropdown Cascata",
      },
    },
  ];
};

/**
 * Retorna o tipo do input de acordo com o seu design
 * @param {object} questionProperties
 * @param {boolean} key - informa o tipo de estrutura a ser entregue
 * @returns {string}
 */
export const inputProps = (questionProperties, key = false) => {
  if (key) {
    return {
      default: { maxLength: 250 },
      email: { maxLength: 150 },
      cpf: { maxLength: 15 },
      tel: { maxLength: 20 },
      date: { maxLength: 10 },
      time: { maxLength: 5 },
      datetime: { maxLength: 17 },
      cnpj: { maxLength: 18 },
    };
  } else {
    if (questionProperties?.tel) {
      return { label: i18n.PHONE, maxLength: 20 };
    } else if (questionProperties?.email) {
      return { label: "E-mail", maxLength: 150 };
    } else if (questionProperties?.cpf) {
      return { label: "CPF", maxLength: 15 };
    } else if (questionProperties?.date) {
      return { label: i18n.DATE, maxLength: 10 };
    } else if (questionProperties?.time) {
      return { label: i18n.TIME, maxLength: 5 };
    } else if (questionProperties?.datetime) {
      return { label: i18n.DATETIME, maxLength: 17 };
    } else if (questionProperties?.cnpj) {
      return { label: "CNPJ", maxLength: 18 };
    } else {
      return { label: i18n.TEXT, maxLength: 250 };
    }
  }
};

/**
 * Descobre se um hexadecimal é escuro ou claro, com base na sua luminosidade
 * @param {string} hexadecimal - (String) #000000
 * @returns {string}
 */
export const getLuminosity = (hexadecimal) => {
  var nib = hexadecimal.split("");
  var r = parseInt(nib[1] + nib[2], 16);
  var g = parseInt(nib[3] + nib[4], 16);
  var b = parseInt(nib[5] + nib[6], 16);
  var luminosity = (r * 299 + g * 587 + b * 114) / 1000;
  return luminosity < 127.5 ? "dark" : "light";
};

/**
 * Retorna se está em ambiente de desenvolvimento
 * @returns {Boolean}
 */
export const isLocalhost = () => {
  return (
    window.location.hostname.indexOf("localhost") >= 0 ||
    window.location.hostname.indexOf("127.0.0.1") >= 0
  );
};

import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import MonacoEditor from "@monaco-editor/react";
import {
  Typography,
  Card,
  Space,
  Button,
  Switch,
  Tooltip,
  Divider,
  Select,
  Modal,
  Table,
  Badge,
  Input,
  Tabs,
} from "antd";
import { useParams } from "react-router-dom";
import { PiQuestionBold } from "react-icons/pi";
import { FaRegCopy, FaExternalLinkAlt, FaRegEdit } from "react-icons/fa";

//components
import HorizontalMenu from "../../../components/app/builder/HorizontalMenu";

//utils
import { getSession, setSession, deleteSession } from "../../../utils/Session";
import i18n from "../../../utils/i18n";

//redux
import { loadingRedux } from "../../../store/application/app.slice";
import { shareData, surveyData } from "../../../store/builder/builder.slice";
import {
  getLinksByIdSurvey,
  getSurveyById,
  createSubLink,
  updateLink,
} from "../../../store/builder/builder.action";
import { fetchHomeFolders } from "../../../store/theme/theme.action";
import { themes } from "../../../store/theme/theme.slice";

//images
import tmh from "../../../assets/images/tablet-mode-hands.jpg";
import PaintBrush from "../../../assets/images/theme/paint-brush.svg";

//styles
import "../../../assets/styles/builder/build.css";

const BuilderSharePage = () => {
  const hostname = window.location.hostname;
  const baseURL =
    hostname.indexOf("localhost") >= 0
      ? "https://devsurvey.inovyo.com"
      : "https://devsurvey.inovyo.com";

  const { Title } = Typography;
  const { surveyid } = useParams();
  const dispatch = useDispatch();
  const loading = useSelector(loadingRedux);
  const shareDataRedux = useSelector(shareData);
  const surveyDataRedux = useSelector(surveyData);
  const themesRedux = useSelector(themes);

  //states
  const [jsonShareData, setJsonShareData] = useState(null);
  const [mainLink, setMainLink] = useState(null);
  const [url, setUrl] = useState(null);
  const [subLinks, setSubLinks] = useState([]);
  const [themeList, setThemeList] = useState([]);
  const [openModalEdition, setOpenModalEdition] = useState(false);

  useEffect(() => {
    dispatch(getSurveyById(surveyid));
    dispatch(fetchHomeFolders(true));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (surveyDataRedux !== null) {
      dispatch(getLinksByIdSurvey(surveyid));
    }
    // eslint-disable-next-line
  }, [surveyDataRedux]);

  useEffect(() => {
    if (themesRedux !== null) {
      let extractedThemes = [];

      themesRedux.themeFull.forEach((t) => {
        if (t.type === "t") {
          extractedThemes.push(t);
        }
      });

      setThemeList(extractedThemes);
    }
    // eslint-disable-next-line
  }, [themesRedux]);

  useEffect(() => {
    if (shareDataRedux !== null) {
      let links = getSession("SHARE_" + surveyid, true);

      let main = links.filter((link) => link.default === true)[0];
      let subs = links.filter((link) => link.default !== true);

      setUrl(main.id);
      setMainLink(main);
      setSubLinks(subs);
      setJsonShareData(links);
    }
    // eslint-disable-next-line
  }, [shareDataRedux]);

  const findThemeByID = (themeId) => {
    let themeFinded = {};
    themeList.forEach((tl) => {
      if (tl.id === themeId) {
        themeFinded = tl;
      }
    });
    return themeFinded;
  };

  const EditSubLink = () => {
    const [defaultLink, setDefaultLink] = useState(false);
    const [title, setTitle] = useState("");
    const [active, setActive] = useState(true);
    const [themeId, setThemeId] = useState({});
    const [url, setUrl] = useState("");
    const [tabletMode, setTabletMode] = useState(null); //tablet mode
    const [modalThemeOpen, setModalThemeOpen] = useState(false);
    const [customScript, setCustomScript] = useState("");

    useEffect(() => {
      if (openModalEdition) {
        const editData = getSession("EDIT_LINK", true);

        if (editData?.default) {
          setDefaultLink(true);
        } else {
          if (editData?.title) setTitle(editData.title);
          setActive(editData?.active && !editData.active);
        }
        setUrl(editData.id);
        setThemeId(findThemeByID(editData.design_id));
        setTitle(editData.urlName);

        if (editData?.custom_script) {
          setCustomScript(editData?.custom_script);
        }
      }
      // eslint-disable-next-line
    }, [openModalEdition]);

    const saveLink = () => {
      let editData = getSession("EDIT_LINK", true);

      const idOriginal = editData.idOriginal;
      delete editData.key;
      delete editData.idOriginal;

      dispatch(updateLink(surveyid, idOriginal, editData));
      setOpenModalEdition(false);
    };

    const changeDataEdition = (value, type) => {
      let editData = getSession("EDIT_LINK", true);

      switch (type) {
        case "title":
          setTitle(value);
          editData.urlName = value;
          break;
        case "url":
          let rx = new RegExp(/^(\w|-)*$/gm);
          if (rx.test(value)) {
            editData.id = value;
            setUrl(value);
          }
          break;
        case "theme":
          setThemeId(findThemeByID(value));
          editData.design_id = value;
          setModalThemeOpen(!modalThemeOpen);
          break;
        case "active-tablet-mode":
          setTabletMode(value);
          if (value !== null) {
            editData.kioski = value;
          } else {
            delete editData.kioski;
          }
          break;
        case "tablet-mode-sleep":
          setTabletMode({ ...tabletMode, sleep: value });
          editData.kioski.sleep = value;
          break;
        case "tablet-mode-restart":
          setTabletMode({ ...tabletMode, restart: value });
          editData.kioski.restart = value;
          break;
        case "status":
          setActive(value);
          if (value) {
            delete editData.status;
          } else {
            editData.status = "closed";
          }
          break;
        case "scripts":
          setCustomScript(value);
          editData.custom_script = value;
          break;
        default:
          break;
      }

      setSession("EDIT_LINK", editData, true);
    };

    const ThemeScrollList = () => {
      const [themeSearch, setThemeSearch] = useState(themeList);
      const [searchText, setSearchText] = useState("");

      const changeSearch = (value) => {
        if (value !== "") {
          let themeSearchPush = [];
          themeList.forEach((t) => {
            if (t.title.toLowerCase().indexOf(value) >= 0) {
              themeSearchPush.push(t);
            }
          });
          setThemeSearch(themeSearchPush);
        } else {
          setThemeSearch(themeList);
        }
        setSearchText(value);
      };

      return (
        <>
          <label htmlFor="search-input" style={{ marginTop: 10 }}>
            Buscar
          </label>
          <Input
            id="search-input"
            value={searchText}
            onChange={({ target: { value } }) => changeSearch(value)}
            style={{ marginBottom: 10 }}
            placeholder="Pesquisar tema..."
          />
          <div id="share-theme-list">
            {themeSearch.map((tl, i) => (
              <div
                className="item"
                key={`share-theme-list-${i}`}
                onClick={() => changeDataEdition(tl.id, "theme")}
              >
                <img src={PaintBrush} alt="PaintBrush" />
                {tl.title}
              </div>
            ))}
            {themeSearch.length === 0 && <div>Tema não encontrado</div>}
          </div>
        </>
      );
    };

    return openModalEdition !== null ? (
      <>
        <Tabs
          defaultActiveKey="url"
          items={[
            {
              key: "url",
              label: "Link",
              children: (
                <>
                  <Space
                    id="share-link-edition"
                    direction="vertical"
                    style={{ width: "100%" }}
                  >
                    {!defaultLink && (
                      <>
                        <label htmlFor="link-title">Título</label>
                        <Input
                          id="link-title"
                          onChange={({ target: { value } }) =>
                            changeDataEdition(value, "title")
                          }
                          value={title}
                          placeholder={title === "" ? "Sem título" : ""}
                          disabled={defaultLink}
                        />
                      </>
                    )}

                    <label htmlFor="link-url">URL</label>
                    <Input
                      id="link-url"
                      onChange={({ target: { value } }) =>
                        changeDataEdition(value, "url")
                      }
                      value={url}
                      addonBefore={baseURL + "/"}
                    />

                    <div>Tema</div>
                    <div
                      className="theme-tag"
                      onClick={() => setModalThemeOpen(!modalThemeOpen)}
                    >
                      <img src={PaintBrush} alt="PaintBrush" /> {themeId?.title}
                    </div>

                    <label htmlFor="link-switch">
                      Modo Tablet{" "}
                      <Tooltip
                        title={
                          <div style={{ textAlign: "center" }}>
                            {i18n.TABLET_MODE_EXPLANATION} <br />
                            <img src={tmh} alt="tmh" />
                          </div>
                        }
                      >
                        <>
                          <PiQuestionBold />
                        </>
                      </Tooltip>
                    </label>
                    <Switch
                      checkedChildren="Ativo"
                      unCheckedChildren="Inativo"
                      defaultChecked={tabletMode !== null}
                      id="link-switch"
                      onChange={(checked) =>
                        changeDataEdition(
                          checked ? { sleep: 60, restart: 10 } : null,
                          "active-tablet-mode"
                        )
                      }
                    />

                    {tabletMode !== null && (
                      <div className="kioski-mode-settings">
                        <label htmlFor="tablet-mode-sleep">
                          <Tooltip title="Caso o usuário abandone a tela, após um certo tempo a pesquisa reinicia.">
                            <span>
                              <PiQuestionBold />
                            </span>
                          </Tooltip>{" "}
                          Tempo inativo{" "}
                        </label>
                        <Select
                          id="tablet-mode-sleep"
                          defaultValue={tabletMode.sleep}
                          style={{
                            width: 120,
                          }}
                          onChange={(value) =>
                            changeDataEdition(value, "tablet-mode-sleep")
                          }
                          options={[
                            {
                              value: 60,
                              label: "1 min",
                            },
                            {
                              value: 120,
                              label: "2 min",
                            },
                            {
                              value: 180,
                              label: "3 min",
                            },
                            {
                              value: 240,
                              label: "4 min",
                            },
                            {
                              value: 200,
                              label: "5 min",
                            },
                          ]}
                        />
                        <br />
                        <br />
                        <label htmlFor="tablet-mode-restart">
                          <Tooltip title="Tempo em que o usuário permanece na tela ao final da pesquisa. Após esse período, a pesquisa reinicia.">
                            <span>
                              <PiQuestionBold />
                            </span>
                          </Tooltip>{" "}
                          Recomeça após{" "}
                        </label>
                        <Select
                          defaultValue={tabletMode.restart}
                          id="tablet-mode-restart"
                          style={{
                            width: 120,
                          }}
                          onChange={(value) =>
                            changeDataEdition(value, "tablet-mode-restart")
                          }
                          options={[
                            {
                              value: 5,
                              label: "5 seg",
                            },
                            {
                              value: 10,
                              label: "10 seg",
                            },
                            {
                              value: 15,
                              label: "15 seg",
                            },
                            {
                              value: 20,
                              label: "20 seg",
                            },
                          ]}
                        />
                      </div>
                    )}

                    {!defaultLink && (
                      <>
                        <label htmlFor="link-active">Link ativo</label>
                        <Switch
                          id="link-active"
                          checkedChildren="Ativo"
                          unCheckedChildren="Inativo"
                          defaultChecked={active}
                          onChange={(checked) =>
                            changeDataEdition(checked, "status")
                          }
                        />
                      </>
                    )}
                  </Space>
                  <Modal
                    title="Escolher tema: "
                    open={modalThemeOpen}
                    onCancel={() => {
                      setModalThemeOpen(false);
                    }}
                    onOk={() => {
                      setModalThemeOpen(false);
                    }}
                    cancelText={i18n.CANCEL}
                    footer={null}
                  >
                    <ThemeScrollList />
                  </Modal>
                </>
              ),
            },
            {
              key: "py",
              label: "Scripts",
              children: (
                <MonacoEditor
                  height="50vh"
                  defaultLanguage="python"
                  value={customScript}
                  theme="vs-dark"
                  onChange={(script) => {
                    changeDataEdition(script, "scripts");
                  }}
                />
              ),
            },
          ]}
        />
        <Space
          direction="horizontal"
          style={{ width: "100%", justifyContent: "flex-end", marginTop: 10 }}
        >
          <Button
            onClick={() => {
              deleteSession("EDIT_LINK");
              setOpenModalEdition(false);
            }}
          >
            {i18n.CANCEL}
          </Button>
          <Button onClick={() => saveLink()} type="primary">
            {i18n.SAVE}
          </Button>
        </Space>
      </>
    ) : (
      <></>
    );
  };

  return (
    <>
      <div id="build-page-share">
        {jsonShareData !== null && !loading && (
          <>
            <HorizontalMenu />
            <Card styles={{ body: { padding: 10 } }}>
              <Button
                onClick={() => {
                  setSession(
                    "EDIT_LINK",
                    {
                      ...mainLink,
                      default: true,
                      idOriginal: mainLink.id,
                    },
                    true
                  );
                  setOpenModalEdition(true);
                }}
                style={{
                  position: "absolute",
                  float: "right",
                  right: "12px",
                  top: "50px",
                }}
                icon={<FaRegEdit />}
              >
                {i18n.EDIT}
              </Button>
              <Divider orientation="left">Principal</Divider>
              <Title level={5} style={{ padding: 5 }}>
                {i18n.DEFAULT}
              </Title>
              <Space
                direction="vertical"
                style={{ marginBottom: "20px", width: "100%", padding: 5 }}
              >
                <div className="url-off">
                  {baseURL}/{url}
                </div>
                <Space direction="horizontal">
                  <Tooltip title={i18n.COPY}>
                    <div>
                      <Button
                        onClick={() => {
                          navigator.clipboard.writeText(`${baseURL}/${url}`);
                          toast.success("Link copiado com sucesso!");
                        }}
                        icon={<FaRegCopy />}
                      />
                    </div>
                  </Tooltip>
                  <Tooltip title={i18n.OPEN}>
                    <div>
                      <Button
                        onClick={() => {
                          window.open(`${baseURL}/${url}`, "_blank").focus();
                        }}
                        icon={<FaExternalLinkAlt />}
                      />
                    </div>
                  </Tooltip>
                </Space>
              </Space>
              <Title level={5} style={{ padding: 5 }}>
                Teste
              </Title>
              <Space
                direction="vertical"
                style={{ marginBottom: "20px", width: "100%", padding: 5 }}
              >
                <div className="editable-url-off">
                  {baseURL}/preview/{url}
                </div>
                <Space direction="horizontal">
                  <Tooltip title={i18n.COPY}>
                    <div>
                      <Button
                        onClick={() => {
                          navigator.clipboard.writeText(
                            baseURL + "/preview/" + url
                          );
                          toast.success("Link copiado com sucesso!");
                        }}
                        icon={<FaRegCopy />}
                      />
                    </div>
                  </Tooltip>
                  <Tooltip title={i18n.OPEN}>
                    <div>
                      <Button
                        onClick={() => {
                          window
                            .open(baseURL + "/preview/" + url, "_blank")
                            .focus();
                        }}
                        icon={<FaExternalLinkAlt />}
                      />
                    </div>
                  </Tooltip>
                </Space>
              </Space>
              <Divider orientation="left">{i18n.RESOURCES}</Divider>
              <Tabs
                defaultActiveKey="links"
                onChange={(key) => {
                  console.log(key);
                }}
                items={[
                  {
                    key: "links",
                    label: "Links",
                    children: (
                      <>
                        {subLinks.length >= 1 ? (
                          <>
                            <Button
                              type="primary"
                              onClick={() => dispatch(createSubLink(surveyid))}
                            >
                              CRIAR
                            </Button>
                            <Table
                              dataSource={subLinks}
                              size="small"
                              style={{ marginTop: 10 }}
                              columns={[
                                {
                                  title: "Título",
                                  dataIndex: "title",
                                  render: (_, row) => (
                                    <>
                                      <Badge
                                        color={
                                          row?.active && !row.active
                                            ? "#aaa"
                                            : "#03a102"
                                        }
                                        count=" "
                                        size="small"
                                        title={
                                          row?.active && !row.active
                                            ? "Inativo"
                                            : "Ativo"
                                        }
                                      />
                                      {` ${
                                        row?.urlName && row.urlName !== ""
                                          ? row.urlName
                                          : "Sem título"
                                      }`}
                                    </>
                                  ),
                                },
                                {
                                  title: "Link",
                                  dataIndex: "link",
                                  width: 70,
                                  align: "center",
                                  render: (_, link) => (
                                    <>
                                      <a
                                        href={`${baseURL}/${link.id}`}
                                        target="_blank"
                                        rel="noreferrer"
                                        title={i18n.OPEN_LINK}
                                      >
                                        <FaExternalLinkAlt />
                                      </a>{" "}
                                      <FaRegCopy
                                        onClick={() => {
                                          navigator.clipboard.writeText(
                                            baseURL + "/" + link.id
                                          );
                                          toast.success(
                                            "Link copiado com sucesso!"
                                          );
                                        }}
                                        style={{ cursor: "pointer" }}
                                        title={i18n.COPY}
                                      />
                                    </>
                                  ),
                                },
                                {
                                  title: "Link (Teste)",
                                  dataIndex: "linkteste",
                                  width: 110,
                                  align: "center",
                                  render: (_, link) => (
                                    <>
                                      <a
                                        href={`${baseURL}/preview/${link.id}`}
                                        target="_blank"
                                        rel="noreferrer"
                                        title={i18n.OPEN_LINK}
                                      >
                                        <FaExternalLinkAlt />
                                      </a>{" "}
                                      <FaRegCopy
                                        onClick={() => {
                                          navigator.clipboard.writeText(
                                            baseURL + "/preview/" + link.id
                                          );
                                          toast.success(
                                            "Link copiado com sucesso!"
                                          );
                                        }}
                                        style={{ cursor: "pointer" }}
                                        title={i18n.COPY}
                                      />
                                    </>
                                  ),
                                },
                                {
                                  title: "",
                                  dataIndex: "edit",
                                  align: "right",
                                  width: 40,
                                  render: (_, link) => (
                                    <FaRegEdit
                                      onClick={() => {
                                        setSession(
                                          "EDIT_LINK",
                                          {
                                            ...link,
                                            idOriginal: link.id,
                                          },
                                          true
                                        );
                                        setOpenModalEdition(true);
                                      }}
                                      style={{ cursor: "pointer" }}
                                    />
                                  ),
                                },
                              ]}
                            />
                          </>
                        ) : (
                          <Card>
                            Nenhum sub link criado
                            <br />
                            <br />
                            <Button
                              type="primary"
                              onClick={() => dispatch(createSubLink(surveyid))}
                            >
                              CRIAR
                            </Button>
                          </Card>
                        )}
                      </>
                    ),
                  },
                  {
                    key: "campaigns",
                    label: "Campanhas",
                    children: "Content of Tab Pane 2",
                  },
                ]}
              />
            </Card>
            <Modal
              title="Edição de URL"
              open={openModalEdition}
              footer={null}
              width={1000}
              onCancel={() => {
                deleteSession("EDIT_LINK");
                setOpenModalEdition(false);
              }}
            >
              <EditSubLink />
            </Modal>
          </>
        )}
      </div>
    </>
  );
};

export default BuilderSharePage;
